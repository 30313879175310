import React from "react";
import {Helmet} from "react-helmet";
import {Navigate, Route, Routes} from "react-router-dom";
import {BoneHome} from "./containers/bone/BoneHome";
import {MRIHome} from "./containers/mri/MRIHome";
import {DNAHome} from "./containers/dna/DNAHome";
import BoneLayoutDefault from "./components/bone/layout/Layout";
import {ResearchHome} from "./containers/research/ResearchHome";

const routes = [
	{
		path: "/bone",
		component: BoneHome,
		className: "bone-layout",
		service: "bone",
		layout: true,
		title: "Bone Scan, Bone Regeneration - Cutting-Edge, Radiation-Free Bone Assessment",
	},
	{
		path: "/mri",
		component: MRIHome,
		service: "mri",
		className: "mri-layout",
		title: "Precision Imaging Unmatched Comfort - Revolutionary Musculoskeletal Assessment",
		layout: true,
	},
	{
		path: "/dna",
		component: DNAHome,
		service: "dna",
		className: "dna-layout",
		title: "Advanced, Non-Invasive Genetic Insights - Unlock Your Genetic Health Potential",
		layout: true,
	},
	{
		path: "/research",
		component: ResearchHome,
		service: "bone",
		className: "bone-layout",
		title: "Latest Research & Publications in REMS Bone Density scan",
		layout: true,
	}
];

export const App = (): React.ReactElement => {

	return (
		<Routes>
			{routes.map(({ path, component: Component, title, className, layout, service }, index) => (
				<Route
					key={index}
					path={path}
					element={
						<>
							{title ? (
								<Helmet>
									<title>{title}</title>
									<link rel="canonical" href={`https://regenphd.com${path}`} />
									<meta name="description" content={title} />
									<meta property="og:title" content={title} />
									<meta property="og:description" content={title} />
									<meta name="twitter:title" content={title} />
									<meta name="twitter:description" content={title} />
								</Helmet>
							) : null}
							{layout ? (
								<BoneLayoutDefault service={service} className={className}>
									<Component />
								</BoneLayoutDefault>
							) : (
								<Component />
							)}
						</>
					}
				/>
			))}

			<Route path="*" element={<Navigate to="bone" replace />} />
		</Routes>
	);
};
